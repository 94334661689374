
.paginaInicio{

	width: 100%;
	height: 100vh;
	background-color: #FEF9E7;
 	display: flex;
   flex-direction: column;
   justify-content: center;
   align-items:center; 	
   overflow: hidden;


}

.imagenPortada{
	height:100%;
	overflow: hidden;

}

.contenido_Divisas{
	margin: 0;
	padding: 0;

}
.contenido_Meteorologia{
	width: 100%;
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: 'Poppins', sans-serif;	
}

.botonConversion{
	position : absolute;
	left: 10%;
	top: 25%;
	width: auto;
	font-size: 0.9rem;
	border-radius: 50%;
}
.botonMeteorologia{
	position : absolute;
	left: 10%;
	top: 40%;
	width: auto;
	font-size: 0.9rem;
	border-radius: 50%;	
}

.botonVientos{
	position : absolute;
	left: 10%;
	top: 55%;
	width: auto;
	font-size: 0.9rem;
	border-radius: 50%;	
}

.botonWhatsapp{
	position : absolute;
	right: 10%;
	top: 80%;
	width: auto;
	font-size: 0.9rem;
	border-radius: 50%;	
	background-color: white;
	border-color: white;
}
.botonWhatsapp:hover{
	background-color: #04aded;
	border-color: transparent;
}


@media screen and (max-width: 480px) { /* pantalla hasta 480px */
.paginaInicio{

	width: 100%;
 	display: block;
 		overflow: hidden;



}
.imagenPortada{
	height: 100%;
	margin-left: -70%;	
}

.botonConversion{
	position : absolute;
	left: 1%;
	top: 22%;
	width: auto;
	font-size: 0.9rem;
	border-radius: 50%;
}
.botonMeteorologia{
	position : absolute;
	left: 1%;
	top: 37%;
	width: auto;
	font-size: 0.9rem;
	border-radius: 50%;	
}
.botonVientos{
	position : absolute;
	left: 1%;
	top: 52%;
	width: auto;
	font-size: 0.9rem;
	border-radius: 50%;	
}
.botonWhatsapp{
	position : absolute;
	right: 2%;
	top: 85%;
	width: auto;
	font-size: 0.9rem;
	border-radius: 50%;	
}

}