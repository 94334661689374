.imgArrial1{
	width:60%;
	height: auto;
	border: 2px solid #04aded;
	border-radius: 10px;
	float: left;
	margin-left: 0;
	margin-bottom: 10px;
	margin-right: 15px;
}



@media screen and (max-width: 480px) { /* pantalla hasta 480px */
	h2{
		margin-top: 15px;
		font-size: 25px;
		text-align: center;
	}
.imgArrial1{
	width:100%;

}

}