
.tituloPlaya{
    width: 100%;
    height: auto;
    padding: 10px 0;
    color: white;
    font-family: 'Mulish', sans-serif;
    font-weight: bold;
    font-size: 1.5rem;
    border-style: solid;
    border-radius: 10px;
    border-width: 2px;
    border-color: #024d68;  
    display: flex;
    justify-content: center;  
    align-items: center;
    background-color: #04aded;
}

.contenido-texto-video{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;    
    background-color: white;
    border-style: solid;
    border-radius: 10px;
    border-width: 2px;
    border-color: #024d68;
}
.contenido-texto-playas{
    width: 70%;
    height: auto;
    margin-left: 0;
    background-color: white;
    text-align: justify;
}
.contenido-texto-playas p{
    padding: 0;
}
.contenido-video-playas{
    width: 320px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px 10px;
    background-color: rgb(176, 180, 176);
    border: 2px solid #04aded;
}

.contenido-foto-publi{
    width: 310px;
    height: auto;
display: none;
    align-items: center;
    justify-content: center;
    margin: 10px 10px;
    background-color: rgb(176, 180, 176);
    border: 2px solid #04aded;
}
.contenido-foto-publi img{
    width: 100%;
}


@media screen and (max-width: 480px) { /* pantalla hasta 480px */
    .contenido-texto-video{
        display: flex;
        flex-direction: column;

    }
    .contenido-texto-playas{
        width: 100%;
        font-size: 0.8rem;

    }
    .tituloPlaya{
        font-size: 1.2rem;
    }

}