
.contenidoPlayas{
    padding: 20px 0;
}
.contenedorPlayas{
    width: 100%;
    height: auto;
    position: relative;
    z-index: 50;
}

.imgPlayas{
    object-fit: cover;
    width:100%;
    height:100%;
}

.areaPlaya{
    height: 4%;
    background-color: transparent;
    position: absolute;
    z-index: 100;
    border-style: solid;
    border-radius: 5px;
    border-width: 2px;
    border-color: transparent;  
    font-family: 'Mulish', sans-serif;
    font-size: 1.8vw;
    color: white; 
    text-align: center;   
    background-color: rgba(0, 0, 0, 0.2);
}
.areaPlaya:hover{
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.5);
}

.areaPlayaTitulo{
    height: 4%;
    width: 30%;
    margin-left: 4%;
    margin-top: 10px;
    background-color: transparent;
    border-style: solid;
    border-radius: 5px;
    border-width: 2px;
    border-color: #04aded;   
    font-size: 1rem; 
    
}


.iconoPublicidad {
    width:6%;
    height:auto;
    border-width: 2px;
    border-radius: 50%;
    border-style: solid;
    border-color: red;
    z-index: 100;
    position: absolute;
    top: 0;
    left: 0;
}
.iconoPublicidad:hover{
    width:6.2%;
}
.posicionNonos {
    top: 61%;
    left: 18%;   
}
.posicionBarracas {
    top: 70%;
    left: 47%;   
}
.posicionFantasy{
    top: 36%;
    left: 79%;      
}
.posicionClases{
    top: 25%;
    left: 78%;     
}




.joaoFernandez{
    width: 17%;
    top: 17%;
    left: 67%;
}
.joaoFernandino{   
    width: 17%;
    top: 13%;
    left: 70%;
}
.tartaruga{
    width: 12%;
    top: 38%;
    left: 47%;    
}
.azeda{
    width: 11%;
    top: 23%;
    left: 67%;
}
.azedina{
    width: 11%;
    top: 21%;
    left: 78%;    
}
.ossos{
    width: 10%;
    top: 28%;
    left: 68%;
}
.armasao{
    width: 12%;
    top: 32%;
    left: 67%;   
}
.canto{
    width: 10%;
    top: 36%;
    left: 66%;   
}
.brava{
    width: 9%;
    top: 34%;
    left: 87%;   
}
.mangunos{
    width: 14%;
    top: 57%;
    left: 38%;    
}
.forno{
    width: 9%;
    top: 50%;
    left: 80%;   
}
.foca{
    width: 9%;
    top: 55%;
    left: 78%;   
}
.ferradura{
    width: 12%;
    top: 63%;
    left: 70%;      
}
.geriba{
    width: 12%;
    top: 78%;
    left: 47%;      
}
.ferradina{
    width: 15%;
    top: 78%;
    left: 59%;      
}
.tucuns{
    width: 12%;
    top: 89%;
    left: 37%;      
}
.rasa{
    width: 10%;
    top: 35%;
    left: 23%;    
}
.pontaVitorio{
    width: 20%;
    top: 6%;
    left: 5%;    
}
.portoDaBarra{
    width: 15%;
    top: 61%;
    left: 48%;  
}

.fotos_playas {
	width:45%;
	height: 300px;
	border: 2px solid #04aded;
	border-radius: 10px;
    margin-bottom: 10px;

}





@media screen and (max-width: 480px) { /* pantalla hasta 480px */
    .areaPlayaTitulo{
        height: 4%;
        width: 45%;
        margin-left: 4%;
        margin-top: 0;
        background-color: transparent;
        border-style: solid;
        border-radius: 5px;
        border-width: 2px;
        border-color: #04aded;   
        font-size: 0.5rem;  
    }

    .fotos_playas {
        width:90%;
        height: auto;
        border: 2px solid #04aded;
        border-radius: 10px;
        margin-bottom: 10px;
        margin-left:  5%;
    
    }

}
