footer {
  background-color: #04aded;
  text-align: center;
  margin-top: auto;
  padding: 8px;
}

footer a{
  margin: 5px  12px;
}

.derechos-de-autor {
  font-size: 15px;
  color: white;
  padding: 10px;
}

.derechos-de-autor a{
  font-size: 15px;
  color: white;
}


@media screen and (max-width: 480px) { /* pantalla hasta 480px */

  .derechos-de-autor {
    font-size: 10px;
    color: white;
    padding: 10px 0;
  }
  
  .derechos-de-autor a{
    font-size: 10px;
    color: white;
  }
  

}