.imgBuceo2 {
	width:40%;
	height: auto;
	border: 2px solid #04aded;
	border-radius: 10px;
	float: left;
	margin-left: 0;
	margin-bottom: 10px;
	margin-right: 15px;
}

.imgBuceo1 {
	width:45%;
	height: auto;
	border: 2px solid #04aded;
	border-radius: 10px;

	float: right;
	margin-left: 15px;
	margin-bottom: 10px;
	margin-right: 0;
}



@media screen and (max-width: 480px) { /* pantalla hasta 480px */

.imgBuceo2 {
	width:100%;
	height: auto;
	float: left;
	margin-left: 0;
	margin-bottom: 10px;
	margin-right: 0;
}

.imgBuceo1 {
	width:100%;
	margin-left: 0;
	margin-bottom: 10px;
	margin-right: 0;
}


}