.imgCanoa1 {
	width:25%;
	height: auto;
	border: 2px solid #04aded;
	border-radius: 10px;
	float: left;
	margin-left: 0;
	margin-bottom: 10px;
	margin-right: 15px;
}

.imgCanoa2 {
	width:80%;
	height: auto;
	border: 2px solid #04aded;
	border-radius: 10px;

	float: right;
	margin-bottom: 10px;
	margin-right: 10%;
}



@media screen and (max-width: 480px) { /* pantalla hasta 480px */

.imgCanoa1 {
	width:100%;
	height: auto;
	float: left;
	margin-left: 0;
	margin-bottom: 10px;
	margin-right: 0;
}

.imgCanoa2 {
	width:100%;
	margin-left: 0;
	margin-bottom: 10px;
	margin-right: 0;
}


}