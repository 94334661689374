.imgKayak1 {
	width:40%;
	height: auto;
	border: 2px solid #04aded;
	border-radius: 10px;
	float: left;
	margin-left: 0;
	margin-bottom: 10px;
	margin-right: 15px;
}

.imgKayak2 {
	width:55%;
	height: auto;
	border: 2px solid #04aded;
	border-radius: 10px;

	float: right;
	margin-left: 15px;
	margin-bottom: 10px;
	margin-right: 0;
}

.imgKayak3 {
	width:26%;
	height: auto;
	border: 2px solid #04aded;
	border-radius: 10px;

	float: left;
	margin-left: 0;
	margin-bottom: 10px;
	margin-right: 15px;
}

@media screen and (max-width: 480px) { /* pantalla hasta 480px */

.imgKayak1 {
	width:100%;
	height: auto;
	float: left;
	margin-left: 0;
	margin-bottom: 10px;
	margin-right: 0;
}

.imgKayak2 {
	width:74%;
	margin-left: 0;
	margin-bottom: 10px;
	margin-right: 0;
}
.imgKayak3 {
	width:25%;
	margin-left: 0;
	margin-bottom: 10px;
	margin-right: 0;
}

}