.listadoPlayas{
	font-family: Arial;
    font-weight: bold;
    font-size: medium;

}

.datosHoy{
	font-family: Arial;
    font-weight: lighter;
    font-size: medium;
    
}

span{
    font-family: Arial;
    font-weight: bold;
    font-size:large;
}

h5{
    margin-top: 20px;
}

@media screen and (max-width: 480px) { /* pantalla hasta 480px */




}