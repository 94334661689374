/*Testimonios */
.testimonios {
    padding: 40px 40px 80px 40px;
}
.testimonios .carousel {
    max-width: 800px;

}
.carousel-item {
    height: 500px;
}

.carousel-item .container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.carousel-item .container i {
    font-size: 150px;
    margin: 10px 10px 20px 10px;
}

.testimonio-texto {
    font-family: 'Share Tech Mono', monospace;
    max-width: 70%;
    font-size: 20px;
    text-align: center;
}
.testimonio-imagen{
    height: 150px;
    width: 150px;
    margin: 10px 10px 20px 10px;    
}
.testimonio-info {
    font-weight: bold;
    text-align: center;
}
.testimonio-info p {
    margin-bottom: 0;
}
.testimonio-info .cliente {
    font-size: 1.2rem;
}
.testimonio-info .cargo {
    font-size: 0.9rem;
    color: #656060;
}

.botonOpiniones{
	height: 50px;
	width: auto;
	margin-bottom: 10px;

}

@media screen and (max-width: 480px) {

	 .testimonio-imagen {
		 height: 100px;
		 width: 100px;
	 }
	 .testimonio-texto {
		 font-size: 15px;
		 max-width: 90%;

	 }
	 
 }
/*
.contenedor-opinion{
	width: 1170px;
	height: 368px;
	margin: 10px 10px 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #fff;
	box-shadow: 0 3px 13px 1px rgb(0 0 0 / 9%) ;

}

.imagen-opinon{
	height: 100%;
	width: auto;

}

.contenedor-texto-opinion{
	text-align: center;
	padding: 40px;
	font-weight: 400;
	line-height: 1.5rem;
}

.nombre-opinion{
	font-size: 1.3rem;
	padding-bottom: 30px;	
	
}

.texto-opinion{
	font-size: 1.1rem;
	text-align: justify;
	margin-top: 15px;
}

*/



