.logo {
  width:30vh;
  max-width:110px;
  height:30vh;
  max-height:110px;
  position:relative;
  background:#04aded;
  margin-left: 10%;
  margin-top: 5px;
  margin-bottom: 5px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 50%;
  border-style: solid;
  border-color: white;
  z-index: 999;
}

.logo p{
      margin: -1px  0;
      font-size: 0.65em;
      font-weight: bold;
      color: white;    
}

@media screen and (max-width: 480px) { /* pantalla hasta 480px */


}