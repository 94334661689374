@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

.monto{
    display: flex;
    flex-direction: column;    
}
.monto label{
  font-size: 1.2rem;
  font-weight: bold;
}
.monto input{
    height: 50px;
    font-size: 17px;
    padding: 0 15px;
    border: 2px solid #999;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.7);
    text-align: center;
    z-index: 999;
  }
.monto input:focus{
    padding: 0 14px;
    border: 2px solid #4534fe;
  }

.contenidoDivisas{
  position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 370px;
    border-radius: 7px;
    box-shadow: 7px 7px 20px rgba(0, 0, 0, 0.5);

}

 .imagen{
    opacity: 0.35;
    position: absolute;
    left: 0;
    top: 100px;
    width: 100%;
    height: auto;
    z-index: 0;
}
.contenidoDivisas header{
  font-size: 28px;
  font-weight: 500;
  text-align: center;  
  margin-bottom: 20px;
}
.etiqueta{
  font-size: 1.2rem;
  font-weight: bold;
}
.zonaConversion{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;   
    margin-top: 20px;
}
.menu-flags{
    background-color: white;
    border: 2px solid #ffffff;
    border-radius: 10px;
    padding: 2px;
    max-width: 150px;
    text-overflow: hidden;
}

.btn-close:hover{
  padding: 10px;
  background-color: transparent;
  
}
#rfs-btn{
  height: 50px;
  overflow: hidden;
}
#rfs-btn:hover{
  background-color: #675AFE;
  color: white;
  font-size: 1.2rem;
}
.exchange-warning{
  margin-top: 10px;
  background-color: rgba(240, 236, 16, 0.65);
  z-index: 999;
  border: 2px solid #ffffff;
  border-radius: 10px;
  padding: 0 5px;
  display: none;
}
.exchange-warning2{
  margin-top: 10px;
  background-color: rgba(240, 237, 96, 0.65);
  z-index: 999;
  border: 2px solid rgb(207, 17, 17);
  border-radius: 10px;
  padding: 0 5px;
  color: rgb(207, 17, 17);
  font-size: 0.9rem;
  display: none;
}
.exchange-rate{
    font-size: 20px;
    font-weight: bold;
    margin: 20px 0;
    background-color: rgba(255, 255, 255, 0.65);
    border-radius: 5px;
    padding: 2px 5px;
    z-index: 999;
  }
.botonConvertir{
    height: 52px;
    color: #fff;
    font-size: 17px;
    cursor: pointer;
    background: #675AFE;
    transition: 0.3s ease;
    padding: 0 10px;
    outline: none;
    border-radius: 5px;
    border: none;
  }
.botonConvertir:hover{
    background: blue;
    font-size: 18px;

  }

 

  @media screen and (max-width: 480px) { /* pantalla hasta 480px */
    .monto label{
      font-size: 0.9rem;
      font-weight: bold;
    }
    .monto input{
      height: 40px;
      font-size: 17px;
      padding: 0 10px;
    }  

 

    #rfs-btn{
      width: 115px;
      
    }

    .contenidoDivisas{
        left: 10%;
        width: 80%;
        padding: 20px;
      }
      .contenidoDivisas header{
        font-size: 22px;
        font-weight: 500;
        margin-left: 0;
      }
 
      .etiqueta{
        font-size: 0.9rem;
        font-weight: bold;
      }

      .menu-flags{
        border-radius: 10px;
        padding: 0;
        max-width: 150px;
    }

    .exchange-warning{
      margin-top: 5px;
      font-size: 0.8rem;

    }
    .exchange-warning2{
      margin-top: 5px;
      font-size: 0.7rem;

    }   

}
