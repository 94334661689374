
.card{
    max-width: 100%;
}

.card-title{
    position: absolute;
    padding: 5px 15px;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    margin-top: 2%;
    font-size: 1.2rem;


}
.card-date{
    position: absolute;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 17%;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 5px;
}
.card-temp{
    position: absolute;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 40%;
    font-size: 45px;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 5px;
}
.card-desc{
    position: absolute;
    padding-left: 20px;
    margin-top: 58%;
    padding-right: 20px;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 5px;

}

.planillaMareas{
    width: 100%;
}

@media screen and (max-width: 480px) { /* pantalla hasta 480px */

}