


.contenido{
	height:auto;
	width: 100%;
	font-family: Arial;
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	align-items: center;	
	margin-right: 5px;
	margin-bottom: 5px;
	padding-top: 10px;
	background-color: transparent;
	margin-top: 5px;
}

.contenedorGaleria {
	height: 480px;

	width: 700px;
	margin: 0  auto; 
}

.mensajes {
	width: 60%;
	text-align: justify;
	margin-top: 100px;

}


@media screen and (max-width: 480px) { /* pantalla hasta 480px */


.contenedorGaleria {
	height: 240px;

	width: 90%;
	margin: 0  auto; 
	margin-bottom: 20px;
	font-size: 0.75em;
}

.mensajes {
	width: 95%;
	font-size: 0.80em;

}

}