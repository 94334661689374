.imgBuggy2 {
	width:45%;
	height: 400px;
	border: 2px solid #04aded;
	border-radius: 10px;

}

.imgBuggy1 {
	width:45%;
	height: 400px;
	border: 2px solid #04aded;
	border-radius: 10px;

}

.imagenesBuggy {
    flex-direction: row;
}



@media screen and (max-width: 480px) { /* pantalla hasta 480px */

    .imagenesBuggy {
        flex-direction: column;
    }    

.imgBuggy2 {
	width:100%;
	height: 350px;

}

.imgBuggy1 {
	width:100%;
	height: 350px;
}


}